import React from 'react'
import UpdateRestaurant from '../components/UpdateRestaurant'

export const UpdatePage = () => {
  return (
    <div>
        <h1 className='text-center'>Update Restaurant</h1>
        <UpdateRestaurant/>
    </div>
  )
}
