import React from 'react'

export const Header = () => {
  return (
    <div>
        <h1 className="font-weight-light display-1 text-center">
            Restaurant Finder
        </h1>
    </div>
  );
};
